<template>
  <Layout>
    <div class="my-lg-3 my-md-6">
      <div class="container">
        <div class="row">
          <div class="col col-md-6 mx-auto text-center">
            <div class="card">
              <div class="card-body p-6">
                <div class="card-title">
                  <h1 class="text-center">Link Your Kegbit&trade; Tap!</h1>
                </div>

                <div class="form-group text-center">
                  <label class="form-label">
                    Please enter the 4-charater code displayed on the tap
                  </label>
                  <input
                    v-model="claimCode"
                    type="text"
                    autofocus="off"
                    maxlength="4"
                    name="claim_code"
                    class="claim-code w-100"
                  />
                </div>

                <div class="form-footer">
                  <button
                    :disabled="!claimCode || claimCode.length != 4"
                    class="btn btn-primary btn-block btn-lg"
                    type="button"
                    @click.prevent="linkDevice"
                  >
                    Link
                  </button>
                </div>
              </div>
            </div>
            <div>
              <router-link :to="{ name: 'home' }">Cancel</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/Main';

export default {
  name: 'LinkKegBit',
  components: {
    Layout,
  },
  data: () => ({
    claimCode: '',
  }),
  methods: {
    linkDevice() {
      this.$store
        .dispatch('devices/claimDevice', { claimCode: this.claimCode })
        .then((id) => {
          this.$router.replace({
            name: 'kegbit_edit',
            params: { id: id },
          });
        })
        .catch((err) => {
          console.error('err', err);
        });
    },
  },
};
</script>

<style lang="scss">
.claim-code {
  font-size: 3em;
  padding: 30px;
  height: auto;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
}
</style>
